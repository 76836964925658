//////////////////////////////////
/// PAGE: SEARCH PAGE ///
////////////////////////////////// 

.search-list-form-wrapper{
    margin-bottom: 15px;
    .search-keyword,
    .search-category,
    .search-inputs-wrap,
    .search-btn-wrap{
        display: inline-block;
        vertical-align: middle;
    }
    .search-keyword,
    .search-category{
        width: 100%;
    }
    .search-keyword{
        position: relative;
        &::after{
            content: '';
            width: 90%;
            margin: 5px auto;
            display: block;
            height: 1px;
            background-color: #eee;
        }
    }
    .text-field{
        height: 32px;
        padding: 0 15px;
        @include border-radius(0); 
        border: none;
        width: 100%;
        font-weight: 500;
    }
    .search-inputs-wrap{
        @include box-shadow(inset 0 0 5px rgba(black,.05));
        border: solid 1px #ccc;
        @include border-radius(5px); 
        padding: 6px;
    }
    .search-category{
        position: relative;
        .text-field{
            padding-right: 30px;
        }
        .list-icon-block{
            position: absolute;
            left: 20px;
            top: 50%;
            @include transform(translateY(-50%));
            font-size: 18px;
            color: $sub-font-color;
            &.icon-size-lg{
                font-size: 20px; 
            }
            &.icon-size-xl{
                font-size: 24px; 
            }
        }
        .search-dropdown{
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            @include border-radius(0 0 3px 3px);
            background-color: #fff;
            @include box-shadow(0 2px 8px rgba(black, .17));
            border-top: 3px solid $primary-color; 
            @include visibility(hidden);
            margin-top: -10px;
            z-index: 9;
            padding: 5px 0;
            &.open{
                @include visibility(visible);
                margin-top: 5px;
            }
            li{
                padding: 10px 20px;
                background-color: #fff;
                border-bottom: 1px solid #ddd;
                position: relative;
                font-size: 14px;
                font-weight: 500; 
                font-family: $semibold-font;
                cursor: pointer;
                padding-left: 60px;
                @include transition(all 0.2s linear);
                &:hover{
                    background-color: #f5f5f5;
                }
                &:last-child{
                    @include border-radius(0 0 3px 3px);
                    border-bottom: none;
                }
            }
        }
        
        .clear-search-btn{
            width: 24px;
            height: 24px;
            line-height: 26px;
            position: absolute;
            right: 5px;
            text-align: center;
            font-size: 10px;
            color: $sub-font-color;
            top: 50%;
            margin-top: -12px;
            z-index: 2;
            &:hover{
                color: #666;
            }
        }
    }
    .search-btn-wrap{
        margin-top: 10px;
        .search-btn{
            font-size: 16px;
            height: 44px;
            @include box-shadow(inset 0 0 5px rgba(black,.05));
        }
    }
    @include min-md{
        
        .search-inputs-wrap{
            width: 80%;
        }
        .search-keyword,
        .search-category{
            width: 50%;
        }
        .search-keyword{ 
            &::after{
                content: '';
                position: absolute;
                right: 0;
                top: 5%;
                width: 1px;
                margin: 0;  
                background-color: #ddd;
                height: 90%; 
            }
        }
        .search-btn-wrap{
            width: 20%;
            margin-top: 0;
            padding-left: 10px;
        }
    }
}
.search-list-widget{
    .search-info-message{
        font-size: 13px;
        font-weight: 500;
        margin-top: 15px;
        color: $sub-font-color;
    }
}
.search-results-list{
    margin-top: 20px;
    .search-result-item{
        margin-bottom: 5px; 
        .search-result-wrap{
            padding-left: 25px;
            .load-more-btn{
                margin-left: 23px;
            }
            &:last-child .search-result-list{
                margin-bottom: 0;
            }
        }
        h4{
            font-family: $semibold-font;
            font-weight: 500;           
            margin-bottom: 10px; 
            .list-icon-block{
                margin-right: 5px;
                color: #888; 
                font-size: 16px;
            }
        }
        .search-result-list{
            font-weight: 500;
            list-style: none;
            font-family: $semibold-font;
            margin-bottom: 15px;
            padding-left: 23px;
            .search-result-list-item{
                margin-bottom: 10px;
            }
            li{
                position: relative;
                padding-left: 30px;
                font-size: 14px;
            }
            .list-count{
                position: absolute;
                left: 0;
                width: 20px;
                text-align: right;
                top: 0;
            }
            .text-link{
                color: $title-font-color;
                text-decoration: none;
                display: inline-block;
                vertical-align: top;
                margin-right: 5px;
                &:hover{
                    color: $primary-color;
                    text-decoration: underline;
                }
            }
            .label-block{
                color: $sub-font-color;
                display: inline-block;
                font-size: 12px;
                margin-right: 5px;
                vertical-align: middle;
            }
        }
    }
    .load-more-btn{ 
        margin-bottom: 25px;
        @include opacity(0.8);
        font-size: 12px;
        font-weight: 600;
        background-color: #eee;
        @include border-radius(3px);
        padding: 5px 15px 8px;
        color: $default-font-color;
        .icon-block{
            font-size: 14px;
            margin-right: 5px;
        }
        .icon-block,
        .text-block{
            display: inline-block;
            vertical-align: middle;
        }
        &:hover{
            background-color: #ddd; 
        }
    }
}
 
