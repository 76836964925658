//////////////////////////
/// Component: Button ///
//////////////////////////

.btn {
    font-size: 14px;
    font-weight: bold; 
    font-family: $title-font;
    display: inline-block;
    white-space: nowrap;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    touch-action: manipulation;
    padding: 0;
    letter-spacing: .01em;
    -webkit-font-smoothing: antialiased;
    border: none;
    text-align: center;
    vertical-align: middle;
    cursor: pointer;
    color: #373e43;
    background-color: transparent;
    position: relative;
    @include transition(all .2s ease-in);
}

.ant-btn{  
    height: 40px;
    font-weight: bold; 
    font-family: $title-font;
    &.ant-btn-sm{
        height: 30px;
    }
}
 
.ant-btn-primary{
    &:not(.ant-btn-round){
        @include border-radius(0);
    }
    &.ant-btn-circle{
        @include border-radius(50%);
    }
    &:hover,
    &:focus {
        @include box-shadow(0 3px 7px rgba($primary-color, .49));
    }
    &.ant-btn-background-ghost{
        
        &:hover,
        &:focus {
            color: #fff;
            @include box-shadow(0 3px 7px rgba($primary-color, .49));
            background-color: $primary-color;
        }
        &:active,
        &.active {
            color: #fff;
            @include box-shadow(0 3px 7px rgba($primary-color, .6));
            background-color: darken($primary-color, 10%);
        } 
    }
}

.c-outline-btn{
    border: solid 1px #b4b4b4;
    background-color: #fff;
    @include border-radius(20px);
    @include box-shadow(0 1px 3px 0 rgba(0,0,0,0.14));
    font-size: 11px; 
    padding: 0 10px;
    color: #838995;
    &:hover,
    &:focus {
        background-color: $primary-color;
        border-color: $primary-color;
        color: #fff;
        @include box-shadow(0 3px 7px rgba($primary-color, .49));
    } 
}