////////////////////////////////////// 
/// PAGE: MAINTENANCE PAGE ///
////////////////////////////////////// 

.maintenance-wrapper{
    background-color: darken($primary-color, 5%);
    min-height: 100vh;
    display: flex;
    align-items: center;
    .wrapper-block{
        max-width: 500px;
        margin: auto;
    }
    .block-header {
        background-color: #fff;
        padding: 20px 30px;
        border-bottom: 4px solid $primary-color;
        text-align: center;
        @include border-radius(4px 4px 0 0);
        @include box-shadow(0 2px 10px 2px rgba(black, 0.22));

        .logo-block {
            display: inline-block;
            width: 130px;
            vertical-align: middle;
        }
    }

    .content-block {
        padding: 50px 30px;
        background-color: #fff;
        @include border-radius(0 0 4px 4px);
        @include box-shadow(0 2px 10px 2px rgba(black, 0.22));
        text-align: center;
    }
    .block-title {
        font-size: 24px;
        font-weight: 600;
        margin-bottom: 15px;
    }
    .block-para {
        font-size: 16px;
        color: #777;
        max-width: 350px;
        margin: auto;
    }
}
