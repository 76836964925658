//////////////////////////////////
/// PAGE: CIRCLE LANDING PAGE ///
//////////////////////////////////
.create-new-circle-widget {
    .intro-block {
        padding: 15px 20px;
        border-bottom: 1px solid #dddddd;
    }

    .intro-para {
        font-size: 14px;
        max-width: 680px;
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm {
        .intro-para {
            font-size: 16px;
        }
    }
}

.purchase-circle-wrapper {
    padding: 30px 20px;
    border-bottom: 1px solid #dddddd;

    .left-media-block {
        padding-bottom: 30px;
        text-align: center;

        img {
            display: inline-block;
        }
    }

    p {
        font-size: 14px;
    }

    .buttons-block {
        text-align: center;

        .ant-btn {
            font-size: 14px;
            display: inline-block;
            padding: 0 20px;
            vertical-align: middle;
        }

        .or-block {
            display: block;
            font-size: 16px;
            font-weight: 600;
            font-family: $title-font;
            margin: 10px 20px;
            color: #9b9b9b;
        }
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm {
        p {
            font-size: 16px;
        }

        .left-media-block {
            padding-bottom: 0;
        }

        .buttons-block {
            text-align: left;

            .or-block {
                display: inline-block;
            }

            .ant-btn {
                margin: 10px 0;
            }
        }
    }

    @include min-md {
        .right-media-block {
            padding-right: 50px;
        }

        .buttons-block {
            .ant-btn {
                padding: 0 40px;
                font-size: 16px;
            }
        }
    }

    @include min-lg {
        .right-media-block {
            padding-top: 15px;
            padding-right: 80px;
        }
    }
}

.what-happens-circle-wrapper {
    padding: 15px 30px 20px;
    border-bottom: 1px solid #dddddd;

    .primary-title-bb {
        margin-bottom: 35px;
    }

    .purchase-circle-item {
        margin-bottom: 30px;
        position: relative;
        border-bottom: 1px solid #eee;
        padding-bottom: 30px;
        text-align: center;

        &.last {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
        }

        .media-block {
            width: 100%;

            img {
                display: inline-block;
            }
        }

        .right-content-block {
            font-size: 14px;
            max-width: 400px;
            margin: auto;
        }
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm {
        .purchase-circle-item {
            .right-content-block {
                font-size: 16px;
            }
        }
    }

    @include min-md {
        .purchase-circle-item {
            border-bottom: none;
            padding-bottom: 0;
            margin-bottom: 0;
            position: relative;
            text-align: left;

            .media-block {
                width: 80px;

                img {
                    max-height: 80px;
                }
            }

            .right-content-block {
                padding-left: 100px;
                position: absolute;
                left: 0;
                width: 100%;
                top: 50%;
                @include transform(translateY(-50%));
            }
        }
    }

    @include min-lg {
        .purchase-circle-item {
            .media-block {
                width: 100px;

                img {
                    max-height: 100%;
                }
            }

            .right-content-block {
                padding-left: 120px;
            }
        }

        .media-large {
            .media-block {
                width: 110px;
            }

            .right-content-block {
                padding-left: 130px;
            }
        }
    }
}

.create-demo-circle-wrapper {
    padding: 15px 30px 20px;

    .btn-wrap {
        padding-top: 30px;

        .ant-btn {
            padding: 0 20px;
            font-size: 16px;
        }
    }

    .simple-form{
        font-size: 13px;
        h4{
            font-size: 13px;
        }
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-md {
        .btn-wrap {
            padding-top: 0;
            text-align: right;
        }
    }
}

.wrap-create-circle-form{
    position: relative;
    .simple-form .ant-form-item{
        margin-bottom: 14px;
    }
    .upload-block-outside{
        .upload-profile-picture{
            height: auto;
            margin-top: 0;
            .upload-profile-right-block{
                margin-left: 0;
                min-height: 0 !important;
            }
        }
        .ant-upload.ant-upload-select{
            width: 100%;
            display: block;
        }
        .ant-upload{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    .title-block{
        font-size: 15px;
        color: $title-font-color;
        font-weight: bold;
        margin-bottom: 20px;
    } 
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm {
        padding-right: 225px;
        .upload-block-outside{
            position: absolute;
            right: 0;
            top: 27px;
            padding: 0 !important;
            width: 210px;
            .upload-profile-picture{ 
                padding: 45px 15px;
            }
        }
        .create-field-block{
            padding-right: 100px;
            position: relative;
            .checkbox-wrap{
                position: absolute;
                right: 0;
                top: 8px;
            }
        }
    }
    @include min-lg {
        .wrap-custom-field{
            padding: 25px 15px;
        }
        .create-field-block{
            .d-lg-flex{
                display: flex;
            }
        }
    }
}

.dragula-custom-field-item-block{
    margin-bottom: 15px;
    position: relative;
    padding-left: 30px; 
    padding-right: 100px;
    &.gu-mirror{
        .icon-drag{
            bottom: auto;
            top: 40px;
            background-color: #fff;
        }
        .hover-option{
            display: none;
        }
        .ant-input{
            height: 34px;
            border: 1px solid #B1B1B1;
        }
        .ant-input-affix-wrapper{
            display: block;
        }
    }
    .icon-drag{
        position: absolute;
        left: 0;
        bottom: 0;
        font-size: 18px;
        color: #C4C4C4;
        cursor: row-resize;
        width: 35px;
        height: 34px;
        padding-right: 3px;
        border: 1px solid #B1B1B1;
        @include border-radius(4px);
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .ant-form-item-label{
        position: relative;
        left: -30px;
    }
    .icon-lock{
        font-size: 16px;
        color: #C4C4C4;
    }
    .hover-option{
        position: absolute;
        right: 0; 
        bottom: 0;
        width: 85px;
        text-align: left;
        @include transition(all 0.2s linear);
    }
    .option-btn{
        font-size: 16px;
        color: #C4C4C4;
        font-weight: normal;
        margin-left: 10px; 
        width: 32px;
        height: 34px;
        border: 1px solid #B1B1B1;
        @include border-radius(4px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        &:hover{
            color: $primary-color;
        }
    }
    .ant-input-affix-wrapper{
        .ant-input{
            padding-right: 40px;
        }
        .ant-input-suffix{
            right: 20px;
        }
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-sm {
        padding-right: 0;
        &:hover .hover-option{
            @include visibility(visible);
        }
        .hover-option{
            right: -75px; 
            @include visibility(hidden);
        }
    }
}
.create-demo-circle-wrapper{
    .ant-form-item-label{
        line-height: 30px;
    }
    .wrap-custom-field{
        position: relative;
        background: #EBEBEB;
        border: 1px solid #B1B1B1;
        @include border-radius(4px);
        padding: 15px;
        .form-actions{
            margin-top: 15px;
        }
        .cancel-btn{
            color: #888;
            margin-left: 5px;
            &:hover{
                color: #666;
            }
        }
        .ant-form-item-label{
            text-align: left;
            line-height: 16px;
            label{
                padding: 0;
            }
        }
       
    }
    .is-private-btn{
        width: 34px;
        height: 34px;
        color: #A3A3A3;
        background-color: #fff;
        border: 1px solid #B1B1B1;
        top: 0;
        margin-top: 35px;
        left: 35px;
        @include border-radius(4px);
        &.active{
            border-color: $primary-color;
            color: $primary-color;
        }
    }
    .done-btn{
        height: 34px;
        margin-left: 10px;
        font-size: 14px;
        padding: 2px 10px 0;
        span{
            margin-right: 10px;
        }
    }
    ///////////////////////
    /// Media queries ///
    /////////////////////// 
    @include min-sm {
        .wrap-custom-field{
            padding-right: 185px;
            .form-actions{
                position: absolute;
                right: 15px;
                top: 55%;
                @include transform(translateY(-50%));
                margin-top: 0;
                .cancel-btn{
                    position: absolute;
                    left: 100%;
                    top: 8px;
                    margin-left: 35px;
                }
            }
        }
    }
}
.create-new-sub-btn-red{
    min-width: 100px;
    height: 32px;
    font-size: 14px;
    font-weight: 600;
    margin-right: 20px;
    .icon-plus{
        margin-right: 5px;
        position: relative;
        top: 1px;
    }
}
.list-style-new{
    margin: 25px 0 25px 15px;
    li{
        position: relative;
        padding-left: 15px;
        font-size: 14px;
        color: #000;
        margin-bottom: 5px;
        &:before{
            position: absolute;
            left: 0;
            top: 6px;
            content: "";
            width: 6px;
            height: 6px;
            background: #000;
            display: block;
            @include border-radius(6px);
        }
    }
    &.ml-0{
        margin-left: 0;
    }
}
.para-small{
    font-size: 13px;
}

.ant-modal{
    @include transition(all 0.2s linear);       
    &.custom-fields-popup{
        top: 150px;
            // @include transform(translateY(-50%));
        .wrap-custom-field{
            position: relative;
            background: #EBEBEB;
            border: 1px solid #B1B1B1;
            @include border-radius(4px);
            padding: 15px;
            .ant-form-explain {
                bottom: -28px;
                font-size: 12px;
                position: absolute;
                left: 0;
                width: 100%;
            }
            
            .ant-input{
                margin-bottom: 15px;
            }
            .form-actions{
                display: block;
                margin-top: 19px;
                .icon-lock{
                    margin-bottom: 5px;
                }
                .icon-delete{
                    margin-left: 0;
                    font-size: 17px;
                    margin-right: 17px;
                    @include transition(all 0.2s linear);
                    &:hover{
                        color: $primary-color;
                        opacity: 0.8;
                    }
                }
                .icon-tick{
                    margin-right: 5px;
                }
            }
            .cancel-btn{
                color: #888;
                margin-left: 0;
                min-width: 100px;
                &:hover{
                    color: #666;
                }
            }
            .ant-form-item-label{
                text-align: left;
                line-height: 16px;
                label{
                    padding: 0;
                }
            }
            .action-btn{
                padding-top: 5px; 
            }
            .w-100{
                width: 100%;
            }
        }
        .ant-input{
            font-size: 14px;
            border-radius: 4px;
            background-color: #fff;
            height: 34px;
            font-weight: 500;
            border: solid 1px #b1b1b1;
            &:focus{
                @include box-shadow(0 0 0 2px rgba(0, 0, 0, 0.2));
            }
        }
        .ant-select-selection{
            border: solid 1px #b1b1b1;
           
        }
        .ant-select-focused{
            .ant-select-selection{
                @include box-shadow(0 0 0 2px rgba(0, 0, 0, 0.2));
            }
        }
        .is-private-btn{
            width: 34px;
            height: 34px;
            color: #A3A3A3;
            background-color: #fff;
            border: 1px solid #B1B1B1;
            @include border-radius(4px);
            &.active{
                border-color: $primary-color;
                color: $primary-color;
            }
        }
        .form-actions{
            display: flex;
            align-items: center;
            height: 100%;
            margin: 0;
            padding-left: 10px;
            .icon-lock{
                min-width: 34px;
                margin: 0 0 0 0;
            }
            .ant-btn{ 
                height: 34px; 
                margin-left: 0;
                font-size: 14px;
                padding: 2px 10px 0;
                min-width: 100px;
            }
            
        }
        .flex-private{
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 8px;
            padding-top: 5px;
            .checkbox-wrap{
                margin-right: 5px;
            }
        }
        @include min-sm {
            min-width: 500px;
            .form-actions{
                min-height: 85px;
            }
           
            .form-actions{
                .icon-lock{
                    margin: 0 0 0 15px;
                }
            }
            .wrap-custom-field{
                .ant-input{
                    margin-bottom: 0;
                }
            }
        }
        @include min-lg {
           
            .create-field-block{
                .d-lg-flex{
                    display: flex;
                }
            }
        }
    }
}
.drag-lock-icon{
    width: 34px;
    height: 34px;
    color: #A3A3A3;
    background-color: #fff;
    border: 1px solid #B1B1B1;
    top: 0;
    margin-top: 35px;
    left: 35px;
    @include border-radius(4px);
    &.active{
        border-color: $primary-color;
        color: $primary-color;
    }
}
.textarea-wrap{
    position: relative;
    .ant-input{
        padding-right: 40px;
    }
    > span{
        position: absolute;
        right: 20px;
        position: absolute;
        top: 50%;
        z-index: 2;
        @include transform(translateY(-50%));
    }
}
.members-settings-table-wrapper{
    .ant-checkbox-wrapper-disabled{
        .ant-checkbox-disabled{
            opacity: 0.8;
        }
    }
}
.ant-popover{
   
    &.custom-message{
        max-width: 450px;
    }
}
.form-category{
    .complete-task-btn{
        background: transparent;
        color: $primary-color;
    }
}
.pop-location-msg{
    .ant-popover-message{
        .anticon{
            font-size: 18px;
        }
        .popover-head{
            display: block;
            margin-bottom: 10px;
            margin-top: 5px;
        }
    }
}