//////////////////////////////////
/// PAGE: NOTES LISTING PAGE ///
//////////////////////////////////
.note-listing-widget {
    .note-listing-item {
        padding: 10px 20px;
        border: solid 1px #fff;
        border-bottom: 1px solid #dddddd;
        @include transition(all 0.2s linear);
        position: relative;

        &:hover {
            //border-color: rgba($primary-color, .5);
            //background-color: rgba($primary-color, .01);
            background-color: #f5f5f5;
        }

        .list-item-media {
            width: 104px;
            height: 64px;
            border: solid 4px white;
            @include box-shadow(0 2px 4px rgba(black, .27));
            @include border-radius(0);
            line-height: 66px;
            font-size: 30px;
            margin-bottom: 10px;
            background-color: #e9e2e2;

            img {
                object-fit: cover;
            }
        }

        .no-image {
            img {
                object-fit: initial;
                width: 32px;
                height: auto;
                display: inline-block;
            }
        }

        .right-content {
            position: relative;
            padding-right: 60px;
            min-height: 60px;
        }

        .right-option-block {
            position: absolute;
            right: 0;
            top: 5px;
        }

        .option-btn {
            font-size: 13px;
            margin-left: 10px;
            color: $sub-font-color;

            &:hover {
                color: #666;
            }
        }

        .delete-btn {
            font-size: 14px;

            &:hover {
                color: $error-color;
            }
        }

        .more-option-btn {
            font-size: 13px;
            margin-left: 10px;
            color: $sub-font-color;

            &:hover {
                color: #666;
            }
        }

        .c-pagination {
            margin-top: 10px;
        }
    }

    .note-title {
        font-size: 14px;
        color: $title-font-color;
        font-family: $semibold-font;
        margin-bottom: 0;
        display: block;
        font-weight: 600;
    }

    a.note-title {
        color: $title-font-color;

        &:hover {
            color: $primary-color;
        }
    }

    .note-title.past {
        color: #888;
    }

    .para-text {
        font-size: 14px;
        margin-bottom: 0;
        word-break: break-word;

        +.para-text {
            margin-top: 10px;
        }
    }

    .custom-para-text {
        margin-bottom: 5px;
         ol {
            padding-left: 24px;
            margin-bottom: 0;
        }
        ul{
            padding-left: 24px;
            margin-bottom: 0;
            list-style: disc;
        }
        p {
            margin-bottom: 0;
            font-size: 14px;
        }
    }

    .para-text.past {
        color: #888;
    }

    .date-block {
        font-size: 12px;
        color: $sub-font-color;
        margin-top: 3px;
    }

    .list-has-attachment {
        position: absolute;
        right: 20px;
        top: 5px;
        font-size: 18px;
        color: $text-color;
    }

    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-xs-medium {
        .note-listing-item {
            .list-item-media {
                // float: left;
                float: right;
                margin-right: 60px;
                margin-bottom: 0;
            }

            .right-content {
                margin-top: 0;
                // padding-right: 160px;
            }
        }

        &--media-right {
            .note-listing-item {
                .list-item-media {
                    float: right;
                }

                .right-content {
                    margin-top: 0;
                    margin-left: 0;
                    // margin-right: 130px;
                }
            }
        }
    }

    @include min-lg {
        .note-listing-item {
            &:hover {
                .right-option-block {
                    @include visibility(visible);
                }
            }

            .right-option-block {
                @include visibility(hidden);
            }
        }
    }
}


//specified for notes list
.note-listing-widget--for-notes{
    .note-listing-item .list-item-media{
        margin-bottom: 15px;
    }
    .list-has-attachment{
        right: 15px;
        &--comments{
            right: 38px;
        }
    }
    .note-listing-item--has-full-icons{
        .right-content{
            padding-right: 80px;
        }
    }
    ///////////////////////
    /// Media queries ///
    ///////////////////////
    @include min-xs-medium {
        .note-listing-item { 
            .list-item-media{
                margin-bottom: 0;
                margin-left: 15px;
            } 
            .list-has-attachment{
                right: 20px;
                &--comments{
                    right: 48px;
                }
            }
        } 
        .note-listing-item--has-media:not(.note-listing-item--has-icon){
            .list-item-media{
                margin-right: 20px;
            }
            .right-content{
                padding-right: 0;
            }
        }
        .note-listing-item--has-full-icons{
            &.note-listing-item--has-media{
                .list-item-media{
                    margin-right: 80px;
                } 
            }
        }
    }
}